<template>
  <div>
    <h1 class="brandsTitle">{{sectionTitle}}</h1>
    <div class="brandLogoGrid">
      <div
        v-for="logo in brandsArray"
        :key="logo.name"
      >
        <router-link
          v-if="logo.slug"
          :to="`/o/${logo.slug}`"
          :aria-label="`${logo.name} Brand`"
          class="imageContainer"
        >
          <v-lazy-image :src="getImgUrl(logo.src)" :alt="logo.name" />
        </router-link>
        <div
          v-else
          class="imageContainer"
        >
          <v-lazy-image :src="getImgUrl(logo.src)" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BrandLogoGrid',
  data () {
    return {

    }
  },
  props: {
    sectionTitle: {
      type: String,
      required: false,
      default: ''
    },
    brandsArray: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  methods: {
    getImgUrl (imageSrc) {
      return require('../assets/' + imageSrc)
    }
  }
}
</script>

<style lang="stylus">
.brandsTitle {
  font-family: 'Bebas Neue', sans-serif;
  text-align: left;
}
.brandLogoGrid {
  width: 100%;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
  grid-gap 20px;
  margin-bottom: 50px;

  .imageContainer {
    display: flex;
    margin: auto;
    width: 140px;
    height: 140px;
  }

  img {
    max-height: 140px;
    max-width: 140px;
    width: auto;
    height: auto;
    display: block;
    margin: auto
    aspect-ratio: initial;
  }
}
</style>
